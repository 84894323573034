import { Element } from "react-scroll";
import styled from "styled-components";
import Lottie from "react-lottie";
import useWindowSize from "../../hooks/useWindowSize";

const HowWeWork = () => {
  const windowSize = useWindowSize();
  const isMobile = windowSize.width <= 480;
  const defaultOptions1 = {
    loop: true,
    autoplay: true,
    animationData: require("../../assets/lottie/discovery.json"),
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const defaultOptions2 = {
    loop: true,
    autoplay: true,
    animationData: require("../../assets/lottie/design.json"),
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const defaultOptions3 = {
    loop: true,
    autoplay: true,
    animationData: require("../../assets/lottie/development.json"),
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const defaultOptions4 = {
    loop: true,
    autoplay: true,
    animationData: require("../../assets/lottie/deployment.json"),
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <Element name="#how">
      <Container>
        <Title>How we Work</Title>
        <Subtitle>
          We follow our four-step process to turn your website idea into reality
        </Subtitle>
        <StepsContainer>
          <Step>
            <Lottie
              options={defaultOptions1}
              width={isMobile ? 120 : 180}
              height={isMobile ? 120 : 180}
            />
            <Text>Discovery</Text>
          </Step>
          <Step>
            <Lottie
              options={defaultOptions2}
              width={isMobile ? 120 : 220}
              height={isMobile ? 120 : 220}
            />
            <Text>Design</Text>
          </Step>
          <Step>
            <Lottie
              options={defaultOptions3}
              width={isMobile ? 120 : 160}
              height={isMobile ? 80 : 110}
            />
            <Text>Development</Text>
          </Step>
          <Step>
            <Lottie
              options={defaultOptions4}
              width={isMobile ? 120 : 160}
              height={isMobile ? 120 : 160}
            />
            <Text>Deployment</Text>
          </Step>
        </StepsContainer>
      </Container>
    </Element>
  );
};

const Container = styled.div`
  padding: 32px 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100vw;
  min-height: calc(100vh - 86px);
  gap: 32px;
  background: linear-gradient(180deg, #d2d2d2 0%, #e9e9e9 100%);
  @media (min-width: 480px) {
    padding: 80px 16px;
  }
`;

const Title = styled.h2`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 130%;
  color: #606060;
`;
const Subtitle = styled.h4`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 150%;
  color: #606060;
`;

const StepsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: row;
  gap: 32px;
  padding-top: 0px;
  flex-wrap: wrap;
  @media (min-width: 480px) {
    padding-top: 60px;
    gap: 52px;
  }
`;
const Step = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 16px;
  gap: 10px;
  width: 80%;
  height: 220px;
  background: #58cfa5;
  border-radius: 20px;
  box-shadow: 1px 1px 5px 1px #0000006b, inset 0px 0px 20px #00000029;
  @media (min-width: 480px) {
    width: 280px;
    height: 280px;
    padding: 40px 16px;
    justify-content: space-between;
  }
`;
const Text = styled.h4`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 140%;
  color: #ffffff;
  @media (min-width: 480px) {
    font-size: 24px;
  }
`;
export default HowWeWork;
