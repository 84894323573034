import styled from "styled-components";
import logoWhiteImg from "../assets/images/logo.svg";
import useWindowSize from "../hooks/useWindowSize";
import { useState, useEffect } from "react";
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export default function Header() {
  const windowSize = useWindowSize();
  const { project } = useParams();
  const navigate = useNavigate();
  const [hoverItem, setHoverItem] = useState<number>(99);
  const [selectedItem, setSelectedItem] = useState<number>(0);
  const scrollToTop = () => scroll.scrollToTop();
  const redirectToHome = () => {
    navigate("/");
  };

  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [open, setOpen] = useState<boolean>(false);
  return (
    <>
      <Container
        style={{
          height:  "unset",
          position: "fixed",
        }}
        isFloating={!project ? scrollPosition > 60 : false}
      >
        <MaxWidthContainer>
          {windowSize.width > 480 ? (
            <HeaderLogo
              src={logoWhiteImg}
              onClick={!project ? scrollToTop : redirectToHome}
            />
          ) : null}

          <HeaderRigth>
            <Item
              onMouseLeave={() => setHoverItem(99)}
              onMouseEnter={() => setHoverItem(0)}
            >
              <ScrollLink
                onClick={() => setSelectedItem(0)}
                to={`#home`}
                smooth={true}
                offset={-86}
                duration={500}
              >
                Home
              </ScrollLink>
            </Item>
            <Item
              onMouseLeave={() => setHoverItem(99)}
              onMouseEnter={() => setHoverItem(1)}
            >
              <ScrollLink
                onClick={() => setSelectedItem(1)}
                to={`#tech`}
                smooth={true}
                offset={-86}
                duration={500}
              >
                Technologies
              </ScrollLink>
            </Item>
            <Item
              onMouseLeave={() => setHoverItem(99)}
              onMouseEnter={() => setHoverItem(2)}
            >
              <ScrollLink
                onClick={() => setSelectedItem(2)}
                to={`#how`}
                smooth={true}
                offset={-86}
                duration={500}
              >
                About Us
              </ScrollLink>
            </Item>
            <Item
              onMouseLeave={() => setHoverItem(99)}
              onMouseEnter={() => setHoverItem(3)}
            >
              <ScrollLink
                onClick={() => setSelectedItem(3)}
                to={`#pricing`}
                smooth={true}
                offset={-86}
                duration={500}
              >
                Pricing
              </ScrollLink>
            </Item>
            <Item
              onMouseLeave={() => setHoverItem(99)}
              onMouseEnter={() => setHoverItem(4)}
            >
              <ScrollLink
                onClick={() => setSelectedItem(4)}
                to={`#form`}
                smooth={true}
                offset={-86}
                duration={500}
              >
                Contact
              </ScrollLink>
            </Item>
            <Underline
              style={{
                transform:
                  hoverItem === 99
                    ? `translateX(calc(57px + ${selectedItem * 140}px))`
                    : `translateX(calc(57px + ${hoverItem * 140}px))`,
              }}
            ></Underline>
          </HeaderRigth>
        </MaxWidthContainer>
      </Container>
      <MobileWrapper>
        <LinksContainer open={open}>
          <ScrollLink
            onClick={() => {
              setSelectedItem(0);
              setOpen(false);
            }}
            to={`#home`}
            smooth={true}
            offset={-80}
            duration={500}
          >
            Home
          </ScrollLink>
          <ScrollLink
            onClick={() => {
              setSelectedItem(1);
              setOpen(false);
            }}
            to={`#tech`}
            smooth={true}
            offset={-80}
            duration={500}
          >
            Technologies
          </ScrollLink>
          <ScrollLink
            onClick={() => {
              setSelectedItem(2);
              setOpen(false);
            }}
            to={`#how`}
            smooth={true}
            offset={-80}
            duration={500}
          >
            About Us
          </ScrollLink>
          <ScrollLink
            onClick={() => {
              setSelectedItem(3);
              setOpen(false);
            }}
            to={`#pricing`}
            smooth={true}
            offset={-80}
            duration={500}
          >
            Pricing
          </ScrollLink>
          <ScrollLink
            onClick={() => {
              setSelectedItem(4);
              setOpen(false);
            }}
            to={`#form`}
            smooth={true}
            offset={-80}
            duration={500}
          >
            Contact
          </ScrollLink>
        </LinksContainer>
        <MobileMenu open={open}>
          <MaxWidthContainer>
            <HeaderLogo
              src={logoWhiteImg}
              onClick={!project ? scrollToTop : redirectToHome}
            />
            <Label checked={open} onClick={() => setOpen(!open)}>
              <span></span>
              <span></span>
              <span></span>
            </Label>
          </MaxWidthContainer>
        </MobileMenu>
      </MobileWrapper>
    </>
  );
}
const Label = styled.label<{ checked: boolean }>`
  display: flex;
  flex-direction: column;
  width: 70px;
  transform: scale(0.6);
  cursor: pointer;
  span {
    background: #da0c81;
    border-radius: 10px;
    height: 7px;
    margin: 7px 0;
    transition: 0.4s cubic-bezier(0.68, -0.6, 0.32, 1.6);
  }
  span:nth-of-type(1) {
    width: 50%;
    transform-origin: ${({ checked }) => (checked ? "bottom" : "none")};
    transform: ${({ checked }) =>
      checked ? "rotatez(45deg) translate(8px,0px)" : "none"};
  }

  span:nth-of-type(2) {
    width: 100%;
    transform-origin: ${({ checked }) => (checked ? "top" : "none")};
    transform: ${({ checked }) => (checked ? "rotatez(-45deg)" : "none")};
  }

  span:nth-of-type(3) {
    width: ${({ checked }) => (checked ? "50%" : "75%")};
    transform-origin: ${({ checked }) => (checked ? "bottom" : "none")};
    transform: ${({ checked }) =>
      checked ? "translate(30px, -11px) rotatez(45deg)" : "none"};
  }
`;
const MobileMenu = styled.div<{ open: boolean }>`
  padding: 0 16px;
  box-shadow: ${({ open }) =>
    open ? "none" : "0px 10px 50px -7px rgba(0, 0, 0, 0.2)"};
  background-color: #e9e9e9;
`;
const MobileWrapper = styled.div`
  position: sticky;
  top: 0;
  width: 100%;
  left: 0;
  z-index: 100;
  @media (min-width: 480px) {
    display: none;
  }
`;
const MaxWidthContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 480px) {
  }
`;
const LinksContainer = styled.div<{ open: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  background-color: #e9e9e9;
  transform: ${({ open }) => (open ? "translateY(0)" : "translateY(-100%)")};
  transition: all 0.3s;
  z-index: -1;
  box-shadow: 0px 10px 50px -7px rgba(0, 0, 0, 0.2);
  padding: 100px 0 20px;
  gap: 20px;
  @media (min-width: 480px) {
    display: none;
  }
  a {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    color: #606060;
    font-size: 16px;
  }
`;
const Container = styled.div<{ isFloating: boolean }>`
  transition: all 0.1s;
  box-shadow: ${({ isFloating }) =>
    isFloating ? "0px 10px 50px -7px rgba(0, 0, 0, 0.2)" : ""};
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  .closed {
    transform: translateX(720px);
    opacity: 0;
  }
  top: 0;
  z-index: 110;
  position: sticky;
  padding: 8px 80px;
  background-color: ${({ isFloating }) =>
    isFloating ? "#e9e9e9" : "#e9e9e90"};
  max-width: 100%;
  overflow: hidden;
  left: 0;
  right: 0;
  @media (max-width: 480px) {
    display: none;
  }
`;
const HeaderLogo = styled.img`
  height: 70px;
  object-fit: contain;
  cursor: pointer;

  @media (max-width: 480px) {
    width: 150px;
  }
`;
const HeaderRigth = styled.div`
  display: flex;
  align-items: center;
  width: 700px;
  position: relative;
  @media (max-width: 480px) {
    width: 100%;
  }
`;
const Underline = styled.span`
  position: absolute;
  bottom: 0px;
  left: 0;
  width: 26px;
  height: 3px;
  background-color: ${({ theme }) => theme.palette.primary};
  transition: all 0.3s ease;
  border-radius: 4px;
  transform: translateX(calc(57px + 0px));
`;
const Item = styled.div`
  padding: 8px 16px;
  width: 20%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  color: #606060;
  font-size: 16px;
  a {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    color: #606060;
    font-size: 16px;
  }
`;
