import styled from "styled-components";
export default function Button({ children }: any) {
  return <ButtonContainer>{children}</ButtonContainer>;
}

const ButtonContainer = styled.button`
  background-color: #da0c81;
  color: #fff;
  padding: 16px 32px;
  font-size: 12px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-family: Poppins;
  font-weight: 500;
  a {
    color: #fff;
    font-size: 12px;
    font-family: Poppins;
    font-weight: 500;
  }
  @media (min-width: 480px) {
    padding: 25px 50px;
    font-size: 16px;
    border-radius: 16px;
    a {
      font-size: 16px;
    }
  }
`;
