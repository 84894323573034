import { useRef, useState } from "react";
import styled from "styled-components";
import emailjs from "@emailjs/browser";
import { useTranslation } from "react-i18next";
import { Element } from "react-scroll";
import Loader from "../../components/Loader";

type InputsState = {
  name: string;
  email: string;
  company: string;
  role: string;
  project: string;
  policy: boolean;
};

export default function ContSix() {
  const { t } = useTranslation();
  const firstContainer: any = useRef();
  const [sending, setSending] = useState(false);
  const [inputs, setInputs] = useState<InputsState>({
    name: "",
    email: "",
    company: "",
    role: "",
    project: "",
    policy: false,
  });

  const [status, setStatus] = useState<
    null | "success" | "error" | "incomplete"
  >(null);

  const sendEmail = () => {
    setSending(true);
    if (
      !inputs.company ||
      !inputs.email ||
      !inputs.name ||
      !inputs.project ||
      !inputs.role
    ) {
      setStatus("incomplete");
      setSending(false);
      setTimeout(() => {
        setStatus(null);
      }, 4000);
      return;
    }
    emailjs
      .send("service_nm4msvt", "template_mj9kzs3", inputs, "B3aTSl8m8w9Ogy7zP")
      .then(
        () => {
          setStatus("success");
          setSending(false);
          setInputs({
            name: "",
            email: "",
            company: "",
            role: "",
            project: "",
            policy: false,
          });
          setTimeout(() => {
            setStatus(null);
          }, 4000);
        },
        (error) => {
          console.log(error);
          setStatus("error");
          setSending(false);
          setInputs({
            name: "",
            email: "",
            company: "",
            role: "",
            project: "",
            policy: false,
          });
          setTimeout(() => {
            setStatus(null);
          }, 4000);
        }
      );
  };

  return (
    <Element name="#form">
      <Container ref={firstContainer}>
        <SectionTitle>Contact Form</SectionTitle>
        <FormContainer>
          <InputContainer>
            <InputTitle>{t("form1")}</InputTitle>
            <Input
              type={"text"}
              placeholder={t("form3")}
              onChange={(e) =>
                setInputs((inputs) => ({ ...inputs, name: e.target.value }))
              }
              value={inputs.name}
            />
          </InputContainer>
          <InputContainer>
            <InputTitle>{t("form4")}</InputTitle>
            <Input
              type={"email"}
              placeholder={t("form6")}
              onChange={(e) =>
                setInputs((inputs) => ({ ...inputs, email: e.target.value }))
              }
              value={inputs.email}
            />
            <InputSubTitle>{t("form5")}</InputSubTitle>
          </InputContainer>
          <InputContainer>
            <InputTitle>{t("form7")}</InputTitle>
            <Input
              type={"text"}
              placeholder={t("form9")}
              onChange={(e) =>
                setInputs((inputs) => ({ ...inputs, company: e.target.value }))
              }
              value={inputs.company}
            />
          </InputContainer>
          <InputContainer>
            <InputTitle>{t("form10")}</InputTitle>
            <Input
              type={"text"}
              placeholder={t("form12")}
              onChange={(e) =>
                setInputs((inputs) => ({ ...inputs, role: e.target.value }))
              }
              value={inputs.role}
            />
            <InputSubTitle>{t("form8")}</InputSubTitle>
          </InputContainer>
          <InputContainer big>
            <InputTitle>{t("form13")}</InputTitle>
            <TextArea
              placeholder={t("form15")}
              onChange={(e) =>
                setInputs((inputs) => ({ ...inputs, project: e.target.value }))
              }
              value={inputs.project}
            />
          </InputContainer>
        </FormContainer>
        {!sending ? (
          <SendButton onClick={sendEmail}>{t("form16")}</SendButton>
        ) : (
          <SendButton>{<Loader />}</SendButton>
        )}

        <EmailStatus
          style={
            status === "success"
              ? { opacity: 1 }
              : status === "error" || status === "incomplete"
              ? { opacity: 1 }
              : { opacity: 0, height: 0 }
          }
        >
          {status === "success"
            ? t("emailSuccess")
            : status === "error"
            ? t("emailError")
            : null}
          {status === "incomplete" ? "Please enter all fields" : null}
        </EmailStatus>
      </Container>
    </Element>
  );
}

const Container = styled.div`
  background: linear-gradient(180deg, #d2d2d2 0%, #e9e9e9 100%);
  padding: 50px 16px;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  position: relative;
  gap: 32px;
`;
const SectionTitle = styled.h2`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 130%;
  color: #606060;
`;
const SendButton = styled.button`
  font-family: "Poppins";
  background-color: #da0c81;
  color: white;
  padding: 15px;
  border: none;
  border-radius: 5px;
  font-size: 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 125px;
  @media (max-width: 480px) {
    width: 100%;
  }
  z-index: 10;
  opacity: 1;
  transition: 0.3s;
`;
const EmailStatus = styled.p`
  color: #da0c81;
  font-size: 22px;
  margin: 20px 0;
  z-index: 10;
  @media (max-width: 480px) {
    font-size: 24px;

    padding: 0 40px 30px 40px;
  }

  transition: 0.3s;
`;
const FormContainer = styled.div`
  width: 100%;
  max-width: 820px;
  display: flex;
  flex: 1;
  margin-top: 8px;
  flex-wrap: wrap;
  justify-content: space-between;
  z-index: 10;
`;
const InputContainer = styled.div<{ big?: Boolean }>`
  width: ${(p) => (p.big ? "100%" : "46%")};
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  @media (max-width: 480px) {
    width: 100%;
  }
`;
const InputTitle = styled.label`
  color: ${({ theme }) => theme.palette.text};
  font-size: 16px;
  padding-bottom: 8px;
`;
const InputSubTitle = styled.label`
  font-size: 14px;
  color: #da0c81;
  margin: 6px 0;
  font-style: italic;
`;
const Input = styled.input<{ big?: Boolean }>`
  height: 40px;
  border-radius: 5px;
  background: ${({ theme }) => theme.palette.background};
  color: ${({ theme }) => theme.palette.text};
  border: 1px solid lightgrey;
  padding: 0 8px;
  font-size: 14px;
  outline: none;
  padding: 2px 18px;
  ::placeholder {
    opacity: 0.7;
    color: ${({ theme }) => theme.palette.text};
  }
`;
const TextArea = styled.textarea`
  height: 200px;
  border-radius: 5px;
  background: ${({ theme }) => theme.palette.background};
  color: ${({ theme }) => theme.palette.text};
  border: none;
  padding: 8px;
  border: 1px solid lightgrey;
  resize: none;
  font-size: 14px;
  outline: none;
  padding: 18px 18px;
  ::placeholder {
    opacity: 0.7;
    color: ${({ theme }) => theme.palette.text};
  }
`;
