import { CircularProgress } from "@mui/material";

export default function Loader() {
  return (
      <CircularProgress
        sx={{
          color: "#fff",
        }}
        size={20}
      />
  );
}

