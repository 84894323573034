import styled from "styled-components";
import logoWhiteImg from "../assets/images/logo.svg";
import locationImg from "../assets/images/location.svg";
import mobileImg from "../assets/images/mobile.svg";
import phoneImg from "../assets/images/phone.svg";
import { Link as ScrollLink } from "react-scroll";
export default function Footer() {
  return (
    <Container>
      <TopContainer>
        <FirstColumn>
          <HeaderLogo src={logoWhiteImg} />
          <Description>
            We are a passionate team of web development experts dedicated to
            bringing your digital ideas to life. With a focus on innovation and
            client satisfaction, we specialize in creating tailored web
            solutions that elevate your online presence. Let us be your partners
            in achieving your digital goals.
          </Description>
        </FirstColumn>
        <Column>
          <TopText>Our Services</TopText>
          <Link>Web Design</Link>
          <Link>Responsive Design</Link>
          <Link>Google Analytics</Link>
          <Link>SEO Optimisation</Link>
        </Column>
        <Column>
          <TopText>Explore More</TopText>
          <Link>
            <ScrollLink to={`#home`} smooth={true} offset={-86} duration={500}>
              Home
            </ScrollLink>
          </Link>
          <Link>
            <ScrollLink to={`#tech`} smooth={true} offset={-86} duration={500}>
              Technologies
            </ScrollLink>
          </Link>
          <Link>
            <ScrollLink to={`#how`} smooth={true} offset={-86} duration={500}>
              About Us
            </ScrollLink>
          </Link>
          <Link>
            <ScrollLink
              to={`#pricing`}
              smooth={true}
              offset={-86}
              duration={500}
            >
              Pricing
            </ScrollLink>
          </Link>
          <Link>
            <ScrollLink to={`#form`} smooth={true} offset={-86} duration={500}>
              Contact
            </ScrollLink>
          </Link>
        </Column>
        <Column>
          <TopText>Contact Us</TopText>
          <ContactItem>
            <ImgSvg src={locationImg}></ImgSvg>
            <TextContact>Coolum, Sunshine Coast QLD AU</TextContact>
          </ContactItem>
          <ContactItem>
            <ImgSvg src={phoneImg}></ImgSvg>
            <EmilLink href="mailto:info@somosweb.com.au">info@somosweb.com.au</EmilLink>
          </ContactItem>
          <ContactItem>
            <ImgSvg src={mobileImg}></ImgSvg>
            <PhoneLink href="tel:+61487258252">+61 487 258 252</PhoneLink>
          </ContactItem>
        </Column>
      </TopContainer>
      <Separate></Separate>
      <Reserved>
        Somos Web Design {new Date().getFullYear()} © All right reserved
      </Reserved>
    </Container>
  );
}
const Container = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100vw;
  min-height: auto;
  gap: 32px;
  background: linear-gradient(180deg, #e9e9e9 0%, #d2d2d2 100%);
  @media (min-width: 480px) {
    padding: 56px 80px 45px;
  }
`;
const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  @media (min-width: 480px) {
    flex-direction: row;
    gap: 50px;
  }
`;
const HeaderLogo = styled.img`
  height: 70px;
  object-fit: contain;
`;
const FirstColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  @media (min-width: 480px) {
    width: 40%;
  }
`;
const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  @media (min-width: 480px) {
    width: 20%;
  }
`;
const Description = styled.p`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 150%;
  color: #606060;
  padding-top: 5px;
`;
const TopText = styled.p`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 140%;
  color: #da0c81;
  padding: 16px 0 22px;
`;
const Link = styled.p`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #606060;
  padding: 6px 0;
  cursor: pointer;
  a {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    color: #606060;
    font-size: 16px;
  }
`;
const Separate = styled.div`
  width: 100%;
  height: 3px;
  background-color: #606060;
  margin-top: 0;
  @media (min-width: 480px) {
    margin-top: 64px;
  }
`;
const Reserved = styled.p`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #606060;
  width: 100%;
  text-align: start;
  @media (min-width: 480px) {
    font-size: 20px;
  }
`;
const ContactItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 18px;
  align-items: center;
  padding: 6px 0;
`;
const ImgSvg = styled.img`
  width: 30px;
  height: 30px;
`;
const TextContact = styled.p`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #606060;
`;
const PhoneLink = styled.a`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #606060;
  text-decoration: none;
`;
const EmilLink = styled.a`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #606060;
  text-decoration: none;
`;