import { useEffect, useContext } from "react";
import { scrollContext } from "../context/scrollContext";

const useWindowScroll = () => {
  const { setScroll } = useContext(scrollContext);

  function scrolling(e: any) {
    const scroll = e.target.scrollingElement.scrollTop;
    setScroll({ scrollToTop: scroll });
  }

  useEffect(() => {
    window.addEventListener("scroll", (e: any) => scrolling(e));
    return () => window.removeEventListener("scroll", (e: any) => scrolling(e));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
};

export default useWindowScroll;
