import { createContext, useState } from "react";

interface ScrollContext {
  scroll: {
    scrollToTop: number;
  };
  setScroll: (userState: any) => void;
}

const scrollContext = createContext<ScrollContext>({
  scroll: {
    scrollToTop: 0,
  },
  setScroll: () => {},
});

const ScrollProvider = ({ children }: { children: React.ReactNode }) => {
  const [scroll, setScroll] = useState({
    scrollToTop: 0,
  });
  return (
    <scrollContext.Provider
      value={{
        scroll,
        setScroll,
      }}
    >
      {children}
    </scrollContext.Provider>
  );
};

export { ScrollProvider, scrollContext };
