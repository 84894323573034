import { Element } from "react-scroll";
import styled from "styled-components";
import Lottie from "react-lottie";
import { Link as ScrollLink } from "react-scroll";

const Pricing = () => {
  const defaultOptions1 = {
    loop: true,
    autoplay: true,
    animationData: require("../../assets/lottie/starter.json"),
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const defaultOptions2 = {
    loop: true,
    autoplay: true,
    animationData: require("../../assets/lottie/business.json"),
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const defaultOptions3 = {
    loop: true,
    autoplay: true,
    animationData: require("../../assets/lottie/premium.json"),
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <Element name="#pricing">
      <Container>
        <Title>Pricing Plans</Title>
        <Subtitle>The Best Solution for Our Clients</Subtitle>
        <PricingsContainer>
          <PricingItem>
            <Lottie options={defaultOptions1} width={240} height={240} />
            <CardTitle>Starter Plan</CardTitle>
            <Price>From $500</Price>
            <Description>
              <li>Landing Page (1 page)</li>
              <li>Mobile responsive</li>
              <li>Customized pre-made theme</li>
              <li>1 hour meeting</li>
              <li>Basic SEO optimisation</li>
              <li>Google Analytics</li>
              <li>Contact Form</li>
              <li>Photo sourcing</li>
            </Description>
            <Button>
              <ScrollLink
                to={`#form`}
                smooth={true}
                offset={-86}
                duration={500}
              >
                Start Now
              </ScrollLink>
            </Button>
          </PricingItem>
          <PricingItem>
            <Lottie options={defaultOptions2} width={240} height={240} />
            <CardTitle>Business Plan</CardTitle>
            <Price>From $1000</Price>
            <Description>
              <li>Up to 3 pages</li>
              <li>Mobile responsive</li>
              <li>Customized pre-made theme</li>
              <li>1 hour meeting</li>
              <li>Basic SEO optimisation</li>
              <li>Google Analytics</li>
              <li>Contact Form</li>
              <li>Photo sourcing</li>
            </Description>
            <Button>
              <ScrollLink
                to={`#form`}
                smooth={true}
                offset={-86}
                duration={500}
              >
                Start Now
              </ScrollLink>
            </Button>
          </PricingItem>
          <PricingItem>
            <Lottie options={defaultOptions3} width={240} height={240} />
            <CardTitle>Premium Plan</CardTitle>
            <Price>From $2000</Price>
            <Description>
              <li>Up to 7 pages</li>
              <li>Mobile responsive</li>
              <li>Customized pre-made theme</li>
              <li>1 hour meeting</li>
              <li>Basic SEO optimisation</li>
              <li>Google Analytics</li>
              <li>Contact Form</li>
              <li>Photo sourcing</li>
            </Description>
            <Button>
              <ScrollLink
                to={`#form`}
                smooth={true}
                offset={-86}
                duration={500}
              >
                Start Now
              </ScrollLink>
            </Button>
          </PricingItem>
        </PricingsContainer>
      </Container>
    </Element>
  );
};

const Container = styled.div`
  padding: 32px 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  position: relative;
  width: 100vw;
  gap: 32px;
  background: #58cfa5;
  @media (min-width: 480px) {
    padding: 80px 16px;
  }
`;

const Title = styled.h2`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 130%;
  color: #f8f9ff;
`;
const Subtitle = styled.h4`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  color: #f8f9ff;
`;
const PricingsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: row;
  gap: 32px;
  padding-top: 0px;
  flex-wrap: wrap;
  @media (min-width: 480px) {
    padding-top: 60px;
    gap: 52px;
  }
`;
const PricingItem = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 16px 16px;
  gap: 16px;
  width: 80%;
  height: auto;
  background: #6b0b5b;
  border-radius: 20px;
  box-shadow: 1px 1px 5px 1px #0000006b, inset 0px 0px 20px #00000029;
  div {
    position: absolute;
  }
  @media (min-width: 480px) {
    width: 310px;
    height: 665px;
  }
`;
const CardTitle = styled.h3`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 140%;
  color: #f8f9ff;
  margin-top: 250px;
`;
const Price = styled.h3`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 130%;
  letter-spacing: -0.02em;
  color: #58cfa5;
  text-align: center;
  @media (min-width: 480px) {
    font-size: 40px;
  }
`;
const Description = styled.ul`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #f8f9ff;
  padding: 0px 32px;
`;
const Button = styled.button`
  background-color: #58cfa5;
  color: #fff;
  padding: 8px 16px;
  font-size: 14px;
  border: none;
  border-radius: 4px;
  font-family: "Poppins";
  font-weight: 500;
  cursor: pointer;
  margin-top: 16px;
  a {
    color: #fff;
    font-size: 14px;
    font-family: "Poppins";
    font-weight: 500;
  }
`;

export default Pricing;
