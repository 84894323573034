import { Element } from "react-scroll";
import styled from "styled-components";
import react from "../../assets/tech/react.svg";
import next from "../../assets/tech/next.svg";
import angular from "../../assets/tech/angular.svg";
import node from "../../assets/tech/node.svg";
import sql from "../../assets/tech/sql.svg";
import postgres from "../../assets/tech/postgres.svg";
import firebase from "../../assets/tech/firebase.svg";
import aws from "../../assets/tech/aws.svg";
import gcp from "../../assets/tech/gcp.svg";
import { useState } from "react";
type ItemType = {
  name: string;
  image: string;
  id: number;
};

const TechStack = () => {
  const [hoverElement, sethoverElement] = useState<number>(99);
  const items: ItemType[] = [
    {
      name: "React",
      image: react,
      id: 0,
    },
    {
      name: "Next Js",
      image: next,
      id: 1,
    },
    {
      name: "Angular",
      image: angular,
      id: 2,
    },
    {
      name: "Node Js",
      image: node,
      id: 3,
    },
    {
      name: "Sql",
      image: sql,
      id: 4,
    },
    {
      name: "Postgresql",
      image: postgres,
      id: 5,
    },
    {
      name: "Firebase",
      image: firebase,
      id: 6,
    },
    {
      name: "Amazon Web Service",
      image: aws,
      id: 7,
    },
    {
      name: "Google Cloud Platform",
      image: gcp,
      id: 8,
    },
  ];

  return (
    <Element name="#tech">
      <Container>
        <Title>Technologies we use</Title>
        <TechContainer>
          {items.map((item) => (
            <Tech
              key={item.name}
              onMouseEnter={() => sethoverElement(item.id)}
              onMouseLeave={() => sethoverElement(99)}
              style={{
                height: item.id === hoverElement ? "130px" : "80px",
                width: item.id === hoverElement ? "130px" : "80px",
                borderRadius: item.id === hoverElement ? "6px" : "50%",
                boxShadow:
                  item.id === hoverElement ? " 1px 1px 12px 0px #f673bd" : "",
              }}
            >
              <TechImage
                src={item.image}
                style={{
                  paddingTop: item.name === "Postgresql" ? "5px" : "0px",
                }}
              />
              <TechName>{item.name}</TechName>
            </Tech>
          ))}
        </TechContainer>
      </Container>
    </Element>
  );
};

const Container = styled.div`
  padding: 66px 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100vw;
  height: auto;
  gap: 32px;
  background: linear-gradient(
    180deg,
    #da0c81 1.4%,
    #ae0666 49.86%,
    #da0c81 97.88%
  );

  @media (min-width: 480px) {
    height: 280px;
    padding: 120px 16px;
  }
`;

const Title = styled.h2`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 130%;
  color: #fff;
  position: absolute;
  top: 52px;
`;

const TechContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(5, 100px);
  align-items: center;
  justify-content: center;
  place-items: center;
  width: 100%;
  gap: 52px;
  padding-top: 60px;
  @media (min-width: 480px) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }
`;

const Tech = styled.div`
  min-width: 80px;
  min-height: 80px;
  background: #6b0b5b;
  display: flex;
  align-items: center;
  border-radius: 50%;
  justify-content: flex-start;
  flex-direction: column;
  overflow: hidden;
  padding-top: 11px;
  gap: 8px;
  transition: all 0.3s ease;
`;

const TechImage = styled.img`
  width: 60px;
  height: 60px;
`;

const TechName = styled.label`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  text-align: center;
`;

export default TechStack;
