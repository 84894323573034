import Providers from "./providers/providers";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./screens";
import ReactGA from "react-ga4";

const App = () => {
  ReactGA.initialize("G-K46L0BF0E7");
  return (
    <Providers>
      <BrowserRouter basename="/">
        <Routes>
          <Route path="/" element={<Home />} />
        </Routes>
      </BrowserRouter>
    </Providers>
  );
};

export default App;
