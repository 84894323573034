import styled from "styled-components";
import { useEffect, useRef } from "react";
import gsap from "gsap";
import Lottie from "react-lottie";
import Button from "../../components/Button";
import { Link as ScrollLink} from "react-scroll";

const Hero = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: require("../../assets/lottie/animation1.json"),
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const refContainer: any = useRef();

  useEffect(() => {
    const ctx = gsap.context(() => {
      gsap.fromTo(
        ".container",
        {
          opacity: 0,
          x: -500,
        },
        {
          x: 0,
          opacity: 1,
          duration: 1,
          stagger: 0.5,
        }
      );
      gsap.fromTo(
        ".item",
        {
          opacity: 0,
          scale: 0.5,
        },
        {
          delay: 1,
          scale: 1,
          opacity: 1,
          duration: 1,
          stagger: 0.5,
        }
      );
    }, refContainer);

    return () => ctx.revert();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container id="#home" ref={refContainer}>
      <SubContainer className="container">
        <Title>
          <Color>Boost</Color> Your Business
        </Title>
        <Title>With a New & Creative</Title>
        <Title>
          <Color>Website</Color>
        </Title>
        <Separator />
        <BottomText>
          Turn your ideas into a powerful website. Connect with your
          <br /> customers and grow your business today
        </BottomText>
        <Separator />
        <Button>
          <ScrollLink
            to={`#form`}
            smooth={true}
            offset={-86}
            duration={500}
          >
            Start Now
          </ScrollLink>
        </Button>
      </SubContainer>
      <LottieContainer>
        <Lottie options={defaultOptions} height={600} width={600} />
      </LottieContainer>
    </Container>
  );
};
const Separator = styled.div`
  width: 100%;
  height: 32px;
`;
const LottieContainer = styled.div`
  display: none;
  @media (min-width: 480px) {
    display: block;
    position: absolute;
    bottom: 0;
    right: 20px;
  }
`;
const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  position: relative;
  width: 100vw;
  height: auto;
  overflow-x: hidden;
  padding: 16px;
  background: linear-gradient(180deg, #e9e9e9 0%, #d2d2d2 100%);
  @media (min-width: 480px) {
    height: 100vh;
    padding: 16px;
  }
`;
const SubContainer = styled.div`
  width: 100;
  padding-top: 0;
  padding-bottom: 5%;
  min-height: auto;
  text-align: start;
  padding-left: 8px;
  @media (min-width: 480px) {
    padding-top: 10%;
    position: relative;
    z-index: 1;
    min-height: 358px;
    width: 100vw;
    padding-left: 80px;
    text-align: justify;
  }
`;

const Title = styled.h1`
  color: ${({ theme }) => theme.palette.text};
  font-family: Poppins;
  font-weight: 700;
  font-size: 46px;
  text-align: left;
  line-height: 60px;
  @media (min-width: 480px) {
    font-size: 72px;
    line-height: 100px;
    text-align: auto;
  }
`;
const BottomText = styled.h4`
  color: ${({ theme }) => theme.palette.text};
  font-family: Poppins;
  font-weight: 500;
  font-size: 20px;
  text-align: left;
`;
const Color = styled.span`
  color: #da0c81;
  font-family: Poppins;
  font-weight: 700;
  font-size: 46px;
  @media (min-width: 480px) {
    font-size: 72px;
  }
`;
export default Hero;
