import { PropsWithChildren } from 'react'
import { ThemeProvider } from 'styled-components'
import { useTheme } from '../context/theme'

import { palette } from './palette'

const StylesProvider = ({
  children,
}: PropsWithChildren<unknown>): JSX.Element => {
  const [selectedTheme]: ['dark' | 'light'] = useTheme()

  const theme = {
    selected: selectedTheme,
    palette: palette[selectedTheme],
  }

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

export default StylesProvider
