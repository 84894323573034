import styled from "styled-components";

import Header from "../components/Header";
import Footer from "../components/Footer";

import Hero from "./components/Hero";
import TechStack from "./components/TechStack";
import Form from "./components/Form";
import { useEffect } from "react";
import useWindowScroll from "../hooks/useWindowScroll";
import HowWeWork from "./components/HowWeWork";
import Pricing from "./components/Pricing";

const Home = () => {
  useWindowScroll();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <Container>
        <Hero />
        <TechStack />
        <HowWeWork />
        <Pricing />
        <Form />
        <Footer />
      </Container>
    </>
  );
};

const Container = styled.div`
  background-color: ${({ theme }) => theme.palette.background};
  max-width: 100vw;
  overflow-x: hidden;
`;

export default Home;
