import { ThemeProvider } from "./theme";
import { ScrollProvider } from "./scrollContext";

const ContextsProviders: React.FC = ({ children }): JSX.Element => (
  <ScrollProvider>
    <ThemeProvider>{children}</ThemeProvider>
  </ScrollProvider>
);

export default ContextsProviders;
